<script>
import { mapActions, mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "XSISubscriptionsManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("xsisubscriptions", {
      contents: state => state.xsi_subscriptions
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },
  watch: {
    selectedAccountId: function(newId) {
      if (newId !== "all") {
        this.getXsiProfiles({ fetch_all: 1 });
      }
    }
  },
  methods: {
    ...mapActions("xsiprofiles", {
      getXsiProfiles: "getXsiProfiles"
    }),
    initContent() {
      return {
        subscription_name: "",
        profile_id: "",
        xsi_package_name: "",
        broadworks_subscription_id: "",
        last_event_received_at: ""
      };
    }
  },
  mounted() {
    this.contentType = "XSI Subscription";
    this.primaryKey = "xsi_subscription_id";
    this.scope = "ac";
  }
};
</script>
<style lang="scss" scoped></style>
