<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Subscription Name") }}</div>
    <div class="details">{{ contentFormInfo.subscription_name }}</div>
    <div class="titles">{{ __("Package Name") }}</div>
    <div class="details">{{ contentFormInfo.xsi_package_name }}</div>
    <div class="titles">{{ __("Profile Name") }}</div>
    <div class="details">{{ contentFormInfo.xsi_profile_name }}</div>
    <div class="titles">{{ __("Subscription ID") }}</div>
    <div class="details">{{ contentFormInfo.broadworks_subscription_id }}</div>
    <div class="titles">{{ __("Events Subscribed") }}</div>
    <div class="details">
      <ul
        id="events"
        style=" margin-top: 5px; margin-bottom: 0px; padding-inline-start: 15px"
      >
        <li v-for="item in contentFormInfo.events" :key="item.xsi_event_name">
          {{ item.xsi_event_name }}
        </li>
      </ul>
    </div>
    <div class="titles">{{ __("Last Event Received At") }}</div>
    <div class="details">{{ contentFormInfo.last_event_received_at }}</div>

    <el-button
      :disabled="!this.canWrite()"
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('XSI Subscription')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Subscription Name')"
                  prop="subscription_name"
                >
                  <el-input v-model="contentForm.subscription_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('XSI Profile Name')" prop="profile_id">
                  <el-select
                    v-model="contentForm.profile_id"
                    style="width: 100%"
                    @change="showPackages"
                    :disabled="contentForm.subscription_name === ''"
                    default-first-option
                  >
                    <el-option
                      v-for="xsi_profile in this.xsi_profiles"
                      :key="xsi_profile.xsi_profile_id"
                      :label="xsi_profile.xsi_profile_name"
                      :value="xsi_profile.xsi_profile_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Subscription Package')"
                  prop="xsi_package_name"
                >
                  <el-select
                    v-model="contentForm.xsi_package_name"
                    style="width: 100%"
                    default-first-option
                    :disabled="
                      contentForm.subscription_name === '' ||
                        contentForm.profile_id === ''
                    "
                  >
                    <el-option
                      v-for="xsi_profile in this.xsi_packages"
                      :key="xsi_profile.xsi_package_name"
                      :label="xsi_profile.xsi_package_name"
                      :value="xsi_profile.xsi_package_name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              v-show="this.canWrite()"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    return {
      rules: {
        subscription_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Subscription name is required")
          }
        ],
        profile_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Profile name is required")
          }
        ],
        xsi_package_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Subscription package name is required")
          }
        ]
      }
    };
  },

  computed: {
    ...mapState("xsisubscriptions", {
      isLoading: state => state.isLoading,
      xsi_packages: state => state.xsi_packages
    }),
    ...mapState("xsiprofiles", {
      xsi_profiles: state => state.xsi_profiles
    })
  },

  methods: {
    ...mapActions("xsisubscriptions", {
      createXsiSubscription: "createXsiSubscription",
      deleteContentMethod: "deleteXsiSubscription",
      getXsiPackages: "getXsiPackages"
    }),
    async showPackages() {
      if (
        this.contentForm.xsi_package_name &&
        this.contentForm.xsi_package_name.length > 0
      ) {
        this.contentForm.xsi_package_name = "";
      }
      await this.getXsiPackages({
        profile_id: this.contentForm.profile_id
      });
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process = this.createXsiSubscription;

          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("XSI Subscription created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("XSI Subscription updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
